import { throwError, Observable, Subscription, map, tap } from 'rxjs'
import { Injectable, Injector, OnDestroy } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http'
import { ErrorMessage } from '../../classes/error-message'
import { AccountService } from '@awork/_shared/services/account-service/account.service'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { AppStore } from '@awork/core/state/app.store'
import { AppQuery } from '@awork/core/state/app.query'
import { ToastService } from '../toast-service/toast.service'
import { SocialConnectErrors } from '@awork/_shared/services/account-service/types'
import { CONNECT_ACCESS_RESTRICTION_SAME_DOMAIN } from '@awork/features/project/services/project-service/types'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  private error: HttpErrorResponse
  private logService: LogService
  private toastService: ToastService
  private accountService: AccountService
  private appQuery: AppQuery
  private appStore: AppStore
  private request: HttpRequest<any>
  private mySubscriptions: Subscription[] = []
  private apiBlockedCounter = 0
  private apiBlockedTimeout: number

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      this.logService = this.injector.get(LogService)
      this.toastService = this.injector.get(ToastService)
      this.accountService = this.injector.get(AccountService)
      this.appQuery = this.injector.get(AppQuery)
      this.appStore = this.injector.get(AppStore)
    } catch (_) {}

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        error => {
          if (error instanceof HttpErrorResponse) {
            this.error = error

            if (error.status === 0 && error.statusText === 'Unknown Error') {
              // After three blocked api calls, reload the page
              this.apiBlockedCounter++
              if (this.apiBlockedCounter >= 3) {
                window.location.reload()
              }
              // Reset the counter after 5 seconds, in the case browser resumed connection normally
              if (!this.apiBlockedTimeout) {
                this.apiBlockedTimeout = window.setTimeout(() => {
                  this.apiBlockedCounter = 0
                  this.apiBlockedTimeout = undefined
                }, 5000)
              }

              const url = error && error.error && error.error.target && error.error.target.__zone_symbol__xhrURL
              this.logService.sendLogDNA('ERROR', `Connection closed unexpectedly: ${url}`, error)
              return void 0
            }

            this.logTrace(error, req)

            switch (error.status) {
              case 400:
                this.handleBadRequest(req)
                break
              case 401:
                const message = error && error.error && error.error.exp ? error.error.exp : ''
                this.handleUnauthorized(message)
                break
              case 404:
                this.handleNotFound()
                break
              case 403:
                this.handleForbidden()
                break
              case 500:
                this.handleServerError(req)
                break
              case 503:
                this.handleTimeout(req)
                break
              default:
                this.showError('Sorry, the request failed. Please try again in a moment', req)
            }
          }
          return throwError(error)
        }
      ),
      map((event: HttpEvent<any>) => {
        this.logTrace(event as HttpResponse<any>, req)
        return event
      })
    )
  }

  /**
   * Handles the bad request error
   * @param {HttpRequest<any>} request
   */
  private handleBadRequest(request: HttpRequest<any>): void {
    if (this.error.headers?.get('trace-id') && this.error.error) {
      this.showError(null, request)
    } else if (!this.appQuery.getAdBlockerErrorOccurred()) {
      // Show the AdBlocker/AdGuard API error just once
      this.appStore.setAdBlockerErrorOccurred(true)
      this.showError(q.translations.errors.AdBlockerError, request)
    }

    if (!this.excludeErrors(request)) {
      this.logService?.sendLogDNA(
        'ERROR',
        `Bad request: ${this.error.url} - Code: ${this.error.error?.code}`,
        this.error
      )
    }
  }

  private handleUnauthorized(message: string): void {
    // If the error response is not a valid API error response
    // the error comes from KONG, therefore, it is likely that the reason is token related
    if (
      (!this.error.error || !(this.error.error.code || this.error.error.Code)) &&
      this.appQuery?.getIsLoggedIn() &&
      this.accountService
    ) {
      this.accountService.refreshSession?.().subscribe()
    }
  }

  private handleForbidden(): void {
    // this.error.error !== null ? this.showError() : this.showError('PERMISSION DENIED !!! Please contact your admin')
  }

  private handleNotFound(): void {
    /*this.error.error !== null
      ? this.showError(this.error.error.message)
      : this.showError('RESOURCE NOT FOUND !!! Please try again in a moment')*/
  }

  /**
   * Handle server errors
   * @param {HttpRequest<any>} request
   */
  private handleServerError(request: HttpRequest<any>): void {
    this.error.error !== null
      ? this.showError(null, request)
      : this.showError(q.translations.Error.serverError, request)
  }

  /**
   * Handle Timeout
   * @param {HttpRequest<any>} request
   */
  private handleTimeout(request: HttpRequest<any>): void {
    this.error.error !== null
      ? this.showError(null, request)
      : this.showError(q.translations.Error.serverUnavailable, request)
  }

  /**
   * Shows the error using the toast component
   * @param {string} message
   * @param {HttpRequest<any>} request
   */
  private showError(message: string, request: HttpRequest<any>): void {
    if (!this.excludeErrors(request) && this.toastService) {
      if (!message) {
        const errorMessage = ErrorMessage.getErrorMessage(this.error)

        this.toastService.show(errorMessage.description, { title: errorMessage.title, type: 'error' })
      } else {
        this.toastService.show(message, { type: 'error' })
      }
    }
  }

  /**
   * Determines if the error should be shown according to some endpoints excluded
   * @param {HttpRequest<any>} request
   * @return {boolean}
   */
  private excludeErrors(request: HttpRequest<any>): boolean {
    const EMPTY_RESPONSE = 'empty-response'

    const excludedCalls = [
      {
        url: 'intelligence/intent',
        method: 'POST'
      },
      {
        url: 'me/stats',
        method: 'POST'
      },
      {
        url: 'v1/search',
        method: 'GET'
      },
      {
        url: 'me/integrations/calendar/items',
        method: 'GET'
      },
      {
        url: 'me/integrations',
        method: 'POST'
      },
      {
        url: 'invitations',
        method: 'POST'
      },
      {
        url: 'assignuserbyemail',
        method: 'POST'
      },
      {
        url: 'accounts/refreshcookie',
        method: 'POST'
      },
      {
        url: 'roles',
        method: 'POST',
        code: 'already-exist-error'
      },
      {
        url: 'referrals',
        method: 'POST',
        code: 'email-already-exists'
      },
      {
        url: 'accounts',
        method: 'PUT',
        code: 'account-exists'
      },
      {
        url: 'accounts/forgotPassword',
        method: 'POST',
        code: EMPTY_RESPONSE // Exclude only when the response is empty
      },
      {
        url: 'accounts/forgotPassword',
        method: 'POST',
        code: 'pending-invitation'
      },
      {
        url: 'changepassword',
        method: 'POST',
        code: 'password-set-failed'
      },
      {
        url: 'timetracking/stop',
        method: 'POST',
        code: 'no-active-time-tracking'
      },
      {
        url: 'accounts/connecttosocialaccount',
        method: 'POST',
        code: SocialConnectErrors.NeverLoggedIn
      },
      {
        url: 'teams',
        method: 'POST',
        code: 'validation-failed'
      },
      {
        url: 'notifications/settings',
        method: 'POST',
        code: 'invalid-operation'
      },
      {
        url: 'roles/moveuser',
        method: 'POST',
        code: 'guest-access-restriction'
      },
      {
        url: 'integrations/calendar/items',
        method: 'GET',
        code: 'no-calendar-integrations-found'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'invalid-response-from-server'
      },
      {
        url: 'clientapplications',
        method: 'POST',
        code: 'duplication-violation'
      },
      {
        url: 'clientapplications',
        method: 'POST',
        code: 'validation-failed'
      },
      {
        url: 'v1/versions',
        method: 'GET'
      },
      {
        url: 'checkcustomsubdomain',
        method: 'POST',
        code: 'duplication-violation'
      },
      {
        url: 'channels',
        method: 'GET',
        code: 'account-disabled-error'
      },
      {
        url: 'accounts/cookie',
        method: 'POST'
      },
      {
        url: 'typeofwork',
        method: 'PUT',
        code: 'duplication-violation'
      },
      {
        url: 'projectstatuses',
        method: 'PUT',
        code: 'duplication-violation'
      },
      {
        url: 'apiusers',
        method: 'POST',
        code: 'duplication-violation'
      },
      {
        url: 'apiusers',
        method: 'PUT',
        code: 'duplication-violation'
      },
      {
        url: 'absences',
        method: 'POST',
        code: 'invalid-operation'
      },
      {
        url: 'tasks/assignuserbyemail',
        method: 'POST',
        code: 'guest-access-restriction'
      },
      {
        url: 'timeentries/',
        method: 'PUT',
        code: 'time-tracking-limit'
      },
      {
        url: 'setassignees',
        method: 'POST',
        code: 'invalid-operation'
      },
      {
        url: 'integrations',
        method: 'GET',
        code: 'invalid-user-email'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'validation-failed'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'token-request-failed'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'invalid-domain'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'user-profile-request-failed'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'domain-request-failed'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'unauthorized'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'already-configured'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'invalid-tenant-id'
      },
      {
        url: '/reauthenticate',
        method: 'POST'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'no-sso-enabled'
      },
      {
        url: 'integrations',
        method: 'POST',
        code: 'scim-integration-already-exists'
      },
      {
        url: 'pdf',
        method: 'GET'
      },
      {
        url: 'referralusersettings',
        method: 'GET'
      },
      {
        url: 'projectconnections/validatecode',
        method: 'POST'
      },
      {
        url: 'projectconnections/accept',
        method: 'POST'
      },
      {
        url: 'verifyEmail',
        method: 'POST'
      },
      {
        url: '/absenceregions',
        method: 'POST',
        // TODO: Ask backend to add a more specific error code for duplicate region
        code: 'invalid-operation'
      },
      {
        url: '/absenceregions',
        method: 'PUT',
        // TODO: Ask backend to add a more specific error code for duplicate region
        code: 'invalid-operation'
      },
      {
        url: '/projectconnections',
        method: 'POST',
        code: CONNECT_ACCESS_RESTRICTION_SAME_DOMAIN
      }
    ]

    // Exclude the call if the url and method match
    return excludedCalls.some(excludedCall => {
      return (
        request.url.includes(excludedCall.url) &&
        request.method === excludedCall.method &&
        (!excludedCall.code ||
          !this.error ||
          (!this.error.error && !excludedCall.code) ||
          (this.error.error && excludedCall.code === this.error.error.code) ||
          (excludedCall.code === EMPTY_RESPONSE && !this.error.error))
      )
    })
  }

  ngOnDestroy() {
    this.mySubscriptions.map(s => s.unsubscribe())
  }

  /**
   * Logs the trace id of the response
   * @param {HttpResponse<any> | HttpErrorResponse} event
   * @param {HttpRequest<any>} request
   */
  private logTrace(event: HttpResponse<any> | HttpErrorResponse, request: HttpRequest<any>): void {
    if (event.headers && event.headers instanceof HttpHeaders && request && request.method !== 'GET') {
      this.logService?.logTrace(event.headers.get('trace-id'), event instanceof HttpErrorResponse)
    }
  }
}
